<template>
    <div class="header">
        <a v-on:click="toggleMenu" class="toggle">
            <font-awesome-icon icon="fa-solid fa-align-justify" />
        </a>
        <nav>
            <div class="btn-group">
                <a class="btn btn-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <img :src="`https://msv.sempher.com.br/api/${imagemPerfil}`" alt="foto-perfil">
                    {{ namePerfil }}
                </a>
                <ul class="dropdown-menu dropdown-menu-end submenu">
                    <li>
                        <a v-on:click="perfil" class="dropdown-item menu-children">
                            Perfil
                            <font-awesome-icon icon="fa-solid fa-user-pen" />
                            <!--<span class="badge rounded-pill bg-danger">50%</span>-->
                        </a>
                    </li>
                    <li v-if="false"><a class="dropdown-item menu-children">Configuração</a></li>
                    <li v-if="false"><a class="dropdown-item menu-children">Ajuda</a></li>
                    <li>
                        <a v-on:click="logout" class="dropdown-item menu-children">
                            Sair
                            <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imagemPerfil: '',
            namePerfil: ''
        }
    },
    created() {

        let infoPerfil = JSON.parse(localStorage.getItem('userKey'))

        this.imagemPerfil = infoPerfil.imagemPerfil

        this.namePerfil = infoPerfil.name;
    },
    methods: {
        toggleMenu() {
            this.$store.commit('toggleMenu')
        },
        perfil() {
            this.$router.push({ path: '/changepassword' })
        },
        logout() {
            localStorage.removeItem('userKey')
            this.$store.commit('setUser', null)
            this.$router.push({ path: '/' })
        }
    }
}
</script>

<style scoped>
/* ----- Parte principal ----------------------------------------------------------------------- */
.header {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*------------------------------------- Font link principal ---------------------------------------*/
.header a {
    font-family: Arial;
}

/*----------------------------------------- Icone toggle ------------------------------------------*/
.header .fa-align-justify {
    margin-left: 15px;
    font-size: 20px;
    color: #744cbc;
    cursor: pointer;
}

/*-------------------------------------- Menu config button ---------------------------------------*/
.header nav {
    padding: 0px !important;
    margin-right: 5px;
}

.header .btn-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: #744cbc;
}

.header .btn-link:hover {
    background-color: transparent;
    color: #3e009b;
}

.header .btn-link img {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    margin-right: 10px;
}

/*------------------------------------- Menu config options ---------------------------------------*/
.header .submenu {
    margin-right: 10px !important;
    width: 250px;
    right: 0;
    border: none;
}

.header .menu-children {
    padding: 10px 20px 10px 20px;
    color: #8568bd;
    ;
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    font-family: Arial;
}

.header .menu-children span,
.header .menu-children svg {
    float: right;
    position: relative;
    top: 2px;
    color: #744cbc;
}

.header .menu-children span {
    color: #fff;
}

.header .menu-children:hover {
    color: #744cbc;
    background-color: transparent;
    font-weight: 500;
    cursor: pointer;
}
</style>
